<script>
import aclModuleClient from '@/core/acl-module-client';
import axios from 'axios';

export default {
  name: 'AclGroupLCRoles',
  data: () => ({
    groupId: null,
    group: {
      loading: true,
      data: { },
    },
    syncing: false,
    roles: [],
    categories: [],
    regions: [],
    aclAssignedRoles: {
      loading: true,
      data: [],
      fields: [
        { key: 'role_id', label: 'Role ID' },
        { key: 'name', label: 'Name' },
        { key: 'actions', label: 'Actions' },
      ],
      total: 0,
      items: [],
      filters: {
        page: 1,
        pageSize: 15,
        role: null,
        application: null,
        region: null,
      },
    },
    availableRoles: {
      loading: true,
      fields: [
        { key: 'role_id', label: 'ID' },
        { key: 'name', label: 'Name' },
        { key: 'selected', label: 'Select' },
      ],
      total: 0,
      paginatedItems: new Map(),
      filters: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      saveBtn: {
        loading: false,
      },
    },
    selectedRolesModal: {
      loading: false,
      table: {
        loading: false,
        fields: [
          { key: 'id', label: 'ID' },
          { key: 'name', label: 'Name' },
          { key: 'regions', label: 'Regions' },
        ],
        items: [],
      },
    },
    removeRoleModal: {
      role: {},
      confirmBtn: {
        loading: false,
      },
    },
  }),
  beforeMount() {
    this.groupId = this.$route.params.group_id;
    return Promise.all([
      this.fetchGroup(),
      this.fetchAvailableRoles(),
      this.changeAvailableRolesPage(this.availableRoles.filters.page),
    ]).then(() => {
      this.fetchAclAssignedRoles();
    });
  },
  computed: {
    groupName() {
      return this.group.data?.title ?? 'Unknown group';
    },
  },
  methods: {
    fetchGroup() {
      this.group.loading = true;
      return aclModuleClient
        .get(`v1/groups/${this.groupId}`)
        .then(response => {
          this.group.data = response.data;
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading group');
        })
        .finally(() => {
          this.group.loading = false;
        });
    },
    syncGroup() {
      this.syncing = true;
      return aclModuleClient
        .post(`v1/groups/${this.groupId}/sync`)
        .then(() => {
          this.$noty.success('Group roles successfully synced.');
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error syncing group');
        })
        .finally(() => {
          this.syncing = false;
        });
    },
    fetchAvailableRoles() {
      const { filters } = this.availableRoles;
      let search = '';
      if (filters.name) search = filters.name;

      const offset = (filters.page - 1) * filters.pageSize;
      const params = { offset, limit: filters.pageSize };

      if (search !== '') {
        params.search = search;
      }

      return axios
        .get('v1/roles', { params })
        .then(({ data: { roles: items, total } }) => {
          const formattedItems = items.map(role => ({
            role_id: role.role_id,
            name: role.name,
            selected: false,
          }));
          this.availableRoles.paginatedItems.set(
            this.availableRoles.filters.page,
            formattedItems,
          );
          this.availableRoles.total = total;
        });
    },
    fetchAclAssignedRoles() {
      this.aclAssignedRoles.loading = true;
      return axios
        .get('v1/roles/acl-assigned-roles', { params: { group_title: this.groupName } })
        .then(aclAssignedRoles => {
          this.aclAssignedRoles.data = aclAssignedRoles.data.map(item => ({
            id: item.id,
            role_id: item.role_id,
            name: item.role.name,
            selected: false,
          }));
          this.aclAssignedRoles.total = aclAssignedRoles.data.length;
          return this.handleApplyGroupRolesFilters();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading group roles');
        })
        .finally(() => {
          this.aclAssignedRoles.loading = false;
        });
    },
    changeAvailableRolesPage(newPage) {
      this.availableRoles.filters.page = newPage;
      this.availableRoles.loading = true;

      const promise = this.availableRoles.paginatedItems.has(this.availableRoles.filters.page)
        ? Promise.resolve()
        : this.fetchAvailableRoles();

      return promise
        .catch(error => {
          console.error(error);
          this.$noty.error('Error loading available roles');
        })
        .finally(() => {
          this.availableRoles.loading = false;
        });
    },
    handleRemoveRole(itemToRemove) {
      itemToRemove.loading = true;
      this.removeRoleModal.role = itemToRemove;
      this.$refs['modal-roles-remove'].show();
      itemToRemove.loading = false;
    },
    handleApplyToAllRoles(regionsToApply) {
      this.selectedRolesModal.table.items.forEach(selectedRole => {
        selectedRole.regions = regionsToApply;
      });
    },
    handleApplyAvailableRolesFilters() {
      this.availableRoles.paginatedItems.clear();
      this.availableRoles.filters.page = 1;
      this.changeAvailableRolesPage(this.availableRoles.filters.page);
    },
    handleApplyGroupRolesFilters() {
      this.aclAssignedRoles.items = this.aclAssignedRoles.data.reduce((items, groupRole) => {
        if (
          this.aclAssignedRoles.filters.role === null || this.aclAssignedRoles.filters.role === groupRole.role_id
        ) {
          items = [
            ...items,
            {
              id: groupRole.id,
              role_id: groupRole.role_id,
              name: groupRole.name,
              loading: false,
            },
          ];
        }
        return items;
      }, [ ]);
    },
    handleSaveRoles() {
      const selectedRoles = [];
      this.availableRoles.paginatedItems.values().forEach(page => {
        page.forEach(role => role.selected && selectedRoles.push(role));
      });

      if (!selectedRoles.length) {
        this.$noty.info('You must select at least one role to assign to this group.');
        this.availableRoles.saveBtn.loading = false;
        return Promise.resolve();
      }

      this.availableRoles.saveBtn.loading = true;

      return new Promise(res => {
        const groupRoles = new Map();
        this.aclAssignedRoles.data.forEach(groupRole => {
          groupRoles.set(groupRole.role_id, {
            role_id: groupRole.role_id,
          });
        });

        selectedRoles.forEach(selectedRole => {
          groupRoles.set(selectedRole.role_id, {
            role_id: selectedRole.role_id,
          });
        });

        const assignedRoles = [ ...groupRoles.values() ];

        res({ acl_assigned_roles: assignedRoles, group_title: this.groupName });
      })
        .then(groupRoles => axios.post('v1/roles/acl-assigned-roles', groupRoles))
        .then(() => {
          this.$noty.success('Group roles successfully saved.');
          this.availableRoles.paginatedItems.values().forEach(page => {
            page.forEach(role => { role.selected = false; });
          });
          this.syncGroup();

          return this.fetchAclAssignedRoles();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to save roles');
        })
        .finally(() => {
          this.availableRoles.saveBtn.loading = false;
        });
    },
    confirmRemoveRole() {
      this.removeRoleModal.confirmBtn.loading = true;
      this.removeRoleModal.role.loading = true;

      const modalHideEventHandler = e => { e.preventDefault(); };
      this.$root.$on('bv::modal::hide', modalHideEventHandler);

      return axios
        .delete(`v1/roles/acl-assigned-roles/${this.removeRoleModal.role.id}`)
        .then(() => {
          this.$noty.success('Role was successfully unassigned from this group.');
          this.aclAssignedRoles.filters.name = null;
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
          this.$refs['modal-roles-remove'].hide();
          this.syncGroup();

          return this.fetchAclAssignedRoles();
        })
        .catch(error => {
          console.error(error);
          this.$noty.error('Error trying to unassign group\'s role');
          this.$root.$off('bv::modal::hide', modalHideEventHandler);
        })
        .finally(() => {
          this.removeRoleModal.confirmBtn.loading = false;
          this.removeRoleModal.role.loading = false;
        });
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="mt-2 mb-4">
          <h4>
            ACL Assigned Roles -
            <b-spinner v-if="group.loading" class="align-middle"></b-spinner>
            <span v-else>{{ groupName }}</span>
          </h4>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col>
        <b-alert variant="info" show>
          Assign this ACL group to legacy roles (LC's) so that users with this role have the same permissions as users
          with the legacy roles.
        </b-alert>
      </b-col>
    </b-row>

    <b-row cols="1" cols-xl="2">
      <!-- #region Group Roles -->
      <b-col class="mb-4">
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              Group Roles
              <sup
                v-b-tooltip:hover
                title="These are the roles currently assigned to this group.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="aclAssignedRoles.fields"
                  :items="aclAssignedRoles.items"
                  :busy="aclAssignedRoles.loading"
                  sticky-header="800px"
                  show-empty
                  hover
                  bordered
                  striped>
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No group roles to show</strong>
                    </div>
                  </template>

                  <template #cell(role_id)="{ item }">
                    <code>{{ item.role_id }}</code>
                  </template>

                  <template #cell(name)="{ item }">
                    <code>{{  item.name }}</code>
                  </template>

                  <template #cell(actions)="{ item }">
                    <div class="text-center m-0">
                      <b-button
                        v-b-tooltip:hover
                        title="Unassign role from group"
                        size="sm"
                        variant="danger"
                        :disabled="item.loading"
                        @click="handleRemoveRole(item)">
                        <b-spinner v-if="item.loading" class="align-middle" small/>
                        <feather v-else type="trash-2"></feather>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->

      <!-- #region Available Roles -->
      <b-col>
        <b-card no-body>
          <b-card-header class="d-flex justify-content-between">
            <b-card-title class="m-0 align-content-center">
              Available Roles
              <sup
              v-b-tooltip:hover
              title="These are all the roles available that can be assigned to this group.">
                <feather type="help-circle"></feather>
              </sup>
            </b-card-title>

            <div>
              <b-button
                class="mr-4"
                variant="outline-secondary"
                title="Show filters"
                v-b-tooltip:hover
                v-b-toggle.collapse-available-roles-filters>
                Filters
                <feather type="filter"></feather>
              </b-button>

              <b-button
                variant="primary"
                v-b-tooltip:hover
                title="Save assign selected roles to this group."
                :disabled="availableRoles.saveBtn.loading"
                @click="handleSaveRoles">
                Save roles
                <b-spinner v-if="availableRoles.saveBtn.loading" class="align-middle ml-2" small/>
                <feather v-else type="save"></feather>
              </b-button>
            </div>
          </b-card-header>

          <b-card-body>
            <b-row>
              <b-col>
                <b-collapse id="collapse-available-roles-filters">
                  <b-card class="mb-3" bg-variant="light" no-body>
                    <b-card-body>
                      <b-card-title class="">
                        Filters
                      </b-card-title>

                      <b-form @submit.prevent>
                        <b-form-row>
                          <b-col>
                            <b-form-group id="arf-group-name" label="Name" label-for="arf-name">
                              <b-form-input
                                id="arf-name"
                                placeholder="Enter role name"
                                v-model="availableRoles.filters.name" />
                            </b-form-group>
                          </b-col>
                        </b-form-row>

                        <b-form-row>
                          <b-col class="d-flex justify-content-end">
                            <b-button
                              prevent
                              type="submit"
                              variant="primary"
                              title="Apply filters"
                              v-b-tooltip:hover
                              :disabled="availableRoles.loading"
                              @click.prevent="handleApplyAvailableRolesFilters">
                              Apply
                              <b-spinner v-if="availableRoles.loading" class="align-middle ml-2" small/>
                              <feather v-else type="filter"></feather>
                            </b-button>
                          </b-col>
                        </b-form-row>

                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-collapse>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table
                  :fields="availableRoles.fields"
                  :items="availableRoles.paginatedItems.get(availableRoles.filters.page)"
                  :busy="availableRoles.loading"
                  @row-clicked="item => (item.selected = !item.selected)"
                  tbody-tr-class="pointerCursor"
                  show-empty
                  hover
                  bordered
                  striped>

                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle mr-2"></b-spinner>
                      <strong>Loading...</strong>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center my-2">
                      <strong>No available roles to show</strong>
                    </div>
                  </template>

                  <template #cell(name)="{ item }">
                    <code>{{ item.name }}</code>
                  </template>

                  <template #cell(selected)="{ item }" >
                    <div class="text-center m-0">
                      <b-form-checkbox
                        :id="`role_${item.role_id}`"
                        v-model="item.selected"/>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-pagination
                  class="my-0"
                  align="center"
                  :value="this.availableRoles.filters.page"
                  :total-rows="availableRoles.total"
                  :per-page="availableRoles.filters.pageSize"
                  @change="changeAvailableRolesPage">
                </b-pagination>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <!-- #endregion -->
    </b-row>

     <!-- #region Remove Roles -->
     <b-modal
      id="modal-roles-remove"
      ref="modal-roles-remove"
      title="Remove group's role">
      <p>Are you sure you want to remove the following role from this group?</p>
      <ul>
        <li>ID: <code>{{ removeRoleModal.role.role_id }}</code></li>
        <li>Name: <code>{{ removeRoleModal.role.name }}</code></li>
      </ul>

      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="secondary"
          :disabled="removeRoleModal.confirmBtn.loading"
          @click="cancel()">
          Cancel
        </b-button>
        <b-button
          size="sm"
          variant="danger"
          :disabled="removeRoleModal.confirmBtn.loading"
          @click="confirmRemoveRole">
          Confirm
          <b-spinner v-if="removeRoleModal.confirmBtn.loading" class="align-middle ml-2" small/>
          <feather v-else type="trash-2"></feather>
        </b-button>


      </template>
    </b-modal>
    <!-- #endregion -->

  </b-container>
</template>

<style>
/**
 * The following is used on Region selection modal to limit the
 * width of the modal. It is invoked via the `size` prop instead
 * of the `class` attribute: `size="max-1500"`.
 */
div.modal .modal-dialog.modal-max-1500 {
  max-width: 1500px !important;
}

.pointerCursor {
  cursor: pointer !important;
}
</style>
